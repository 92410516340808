import './index.scss'
// import '../../assets/scripts/myNiceScroll'
// import {resizeScroll} from '../../assets/scripts/myNiceScroll'
import Swiper from 'swiper/swiper-bundle.min.js'
import wx from 'jweixin-npm';

palyvideo()
function palyvideo(){
  if($('video').length>0){
    let videolist = $('video')
    console.log(videolist)
    for(let i=0; i<videolist.length; i++){
      videolist[i].play()
    }
  }
}

document.addEventListener("WeixinJSBridgeReady", function () {
  palyvideo()
}, false);

//banner
var indexbannerswiper = new Swiper(".index-banner .mySwiper", {
  loop: true,
  speed: 800,
  // autoplay: {
  //   delay: 6000,
  //   stopOnLastSlide: false,
  //   disableOnInteraction: false,
  //   waitForTransition: false,
  // },
  pagination: {
    el: ".index-banner .swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".index-banner .swiper-button-next",
    prevEl: ".index-banner .swiper-button-prev",
  },
})

//Industries
var Industriesswiper_img = new Swiper(".section2 .bg-swiper", {
  speed: 800,
  allowTouchMove: false,
  effect: 'fade',
})
var Industriesswiper_text = new Swiper(".section2 .text-swiper .mySwiper", {
  allowTouchMove: false,
  effect: 'fade',
  fadeEffect: {
    crossFade: true,
  },
  on:{
    init: function(swiper){
      $('.section2 .swiper-tab>div').eq(0).addClass('active')
    },
    slideChangeTransitionStart: function(){
      $('.section2 .swiper-tab>div').eq(this.realIndex).addClass('active').siblings().removeClass('active')
      Industriesswiper_img.slideTo(this.realIndex)
    },
  },
})

var mouseover_tid = [];
var mouseout_tid = [];
$(document).ready(function () {
  $(".section2 .swiper-tab>div").each(function (index) {
    $(this).hover(
      // 鼠标进入
      function () {
        var _self = this;
        // 停止卷起事件
        clearTimeout(mouseout_tid[ index ]);
        // 当鼠标进入超过 0.2 秒, 展开菜单, 并记录到线程 ID 中
        mouseover_tid[ index ] = setTimeout(function () {
          Industriesswiper_text.slideTo(index)
        }, 201);
      },
      // 鼠标离开
      function () {
        var _self = this;
        // 停止展开事件
        clearTimeout(mouseover_tid[ index ]);
        // 当鼠标离开超过 0.2 秒, 卷起菜单, 并记录到线程 ID 中
        mouseout_tid[ index ] = setTimeout(function () {
        }, 201);
      });
    });

    if($('.section1 .three').length > 3){
      $('.section1').addClass('more')
    }
});

